import { onCellInputUpdate } from '../../actionCreators';
import { Columns } from '../../constants';
import { formatPriceForDisplay } from '../../helpers/promotions';
import getPromotionById from '../../reducers/gridViewPage/getPromotionById';

export default (store, next, action) => {
  const { dispatch, getState } = store;
  const { GridViewPage } = getState();
  const {
    payload: { promotionId, columnName, value },
  } = action;

  if (![
    Columns.CIRCULAR_PRICE.name,
    Columns.PRIME_MEMBER_LABEL.name,
  ].includes(columnName)) {
    return next(action);
  }

  if (!promotionId || !columnName) {
    return next(action);
  }
  const promotion = getPromotionById(GridViewPage, promotionId);
  const promotionWithUpdatedActiveCellValue = {
    ...promotion,
    [columnName]: value,
  };

  const { id, circularPrice, isPrimeBenefit, priceConditions, primeMemberLabel, promotionType, unitOfMeasure } = promotionWithUpdatedActiveCellValue;
  if (columnName === Columns.CIRCULAR_PRICE.name) {
      const {
        circularPriceDisplay,
      } = formatPriceForDisplay(circularPrice, isPrimeBenefit, priceConditions, primeMemberLabel, promotionType, unitOfMeasure);
      dispatch(onCellInputUpdate(id, Columns.CIRCULAR_PRICE_DISPLAY.name, circularPriceDisplay));
  }
  if (columnName === Columns.PRIME_MEMBER_LABEL.name) {
    const {
      primeMemberLabelDisplay,
    } = formatPriceForDisplay(circularPrice, isPrimeBenefit, priceConditions, primeMemberLabel, promotionType, unitOfMeasure);
    dispatch(onCellInputUpdate(id, Columns.PRIME_MEMBER_LABEL_DISPLAY.name, primeMemberLabelDisplay));
  }

  return next(action);
}