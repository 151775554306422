/* eslint-disable camelcase, max-len */
import createColumn from '../helpers/createColumn';
import { createBBColumn } from '../helpers/bulletinBoard';
import { PromotionTypes } from './promotions';

export const PROGRAM_NAME = 'F3Circulator';

export const NOOP = 'NOOP';
// TODO: better organize this actions, should be under an enum
export const FETCH_PROMOTION_WEEKS = 'FETCH_PROMOTION_WEEKS';
export const KEYPRESS = 'KEYPRESS';
export const CLEAR_PROMOTIONS = 'CLEAR_PROMOTIONS';
export const SET_PROMOTIONS_LOADING = 'SET_PROMOTIONS_LOADING';
export const SET_PROMOTIONS_LOADED = 'SET_PROMOTIONS_LOADED';
export const SET_PROMOTIONS = 'SET_PROMOTIONS';
export const SET_NEW_USER_EXPERIENCE_SHOWN = 'SET_NEW_USER_EXPERIENCE_SHOWN';
export const ON_METADATA_RECEIVED = 'ON_METADATA_RECEIVED';
export const ON_UPDATE_METADATA = 'ON_UPDATE_METADATA';
export const ON_ADD_METADATA_ENTRY = 'ON_METADATA_ENTRY';
export const ON_REMOVE_METADATA_ENTRY = 'ON_REMOVE_METADATA_ENTRY';
export const ON_CLEAR_METADATA = 'ON_CLEAR_METADATA';
export const REST_API = 'REST_API';
export const PAGE_INIT = 'PAGE_INIT';
export const GRAPHQL_ACTION = 'GRAPHQL_ACTION';
export const GET_GRAPHQL_TYPES = 'GET_GRAPHQL_TYPES';
export const SET_GRAPHQL_TYPES = 'SET_GRAPHQL_TYPES';
export const SELECT_BUSINESS = 'SELECT_BUSINESS';
export const SELECT_YEAR_QUARTER = 'SELECT_YEAR_QUARTER';
export const SELECT_TIMEFRAME = 'SELECT_TIMEFRAME';
export const OPEN_TOAST = 'OPEN_TOAST';
export const CLOSE_TOAST = 'CLOSE_TOAST';
export const TOGGLE_SIDE_MENU = 'TOGGLE_SIDE_MENU';
export const UPDATE_REQUIRED_FIELDS = 'UPDATE_REQUIRED_FIELDS';
export const ADD_ERROR_TO_PROMOTION = 'ADD_ERROR_TO_PROMOTION';
export const SHOW_LOADING_SPINNER = 'SHOW_LOADING_SPINNER';
export const HIDE_LOADING_SPINNER = 'HIDE_LOADING_SPINNER';
export const Form = Object.freeze({
  PROMO: 'PromoForm',
  PROMO_WEEK: 'PromoWeekForm',
  BANNER: 'BannerForm',
});
export const SiteWideActions = Object.freeze({
  SET_CONNECTION_ERROR: 'SiteWideActions/SET_CONNECTION_ERROR',
});
export const GridViewActions = Object.freeze({
  ACTIVE_CELL_INPUT_SUBMIT: 'ACTIVE_CELL_INPUT_SUBMIT',
  ACTIVE_CELL_INPUT_UPDATE: 'ACTIVE_CELL_INPUT_UPDATE',
  CELL_INPUT_UPDATE: 'CELL_INPUT_UPDATE',
  FLASH_FILL: 'FLASH_FILL',
  RESET_ACTIVE_CELL: 'RESET_ACTIVE_CELL',
  SELECT_CELL: 'SELECT_CELL',
  UNSELECT_CELL: 'UNSELECT_CELL',
  UPDATE_ACTIVE_CELL: 'UPDATE_ACTIVE_CELL',
  MOVE_ACTIVE_CELL: 'MOVE_ACTIVE_CELL',
  SET_ACTIVE_CELL_EDIT_MODE: 'SET_ACTIVE_CELL_EDIT_MODE',
  PARSE_FILTERS_FROM_URL: 'PARSE_FILTERS_FROM_URL',
  SET_PAGE: 'SET_PAGE',
  TOGGLE_ACTION_BAR: 'TOGGLE_ACTION_BAR',
  CREATED_PROMOTION_RECEIVED: 'CREATED_PROMOTION_RECEIVED',
  SEND_PROMOTION_EDIT: 'SEND_PROMOTION_EDIT',
  PARSE_PROMOTION_ASINS: 'PARSE_PROMOTION_ASINS',
  FETCH_PROMOTION: 'FETCH_PROMOTION',
  SET_SOURCE: 'SET_SOURCE',
  ADD_ROW: 'ADD_ROW',
  REMOVE_ROW: 'REMOVE_ROW',
  COMMIT_ROWS: 'COMMIT_ROWS',
  SET_ROWS: 'SET_ROWS',
  RESET_PREVIEW_ROWS: 'RESET_SOURCE_ROWS',
  LOAD_PROMOTIONS_AUDIT_LOG_FOR_HIGHLIGHT: 'LOAD_PROMOTIONS_AUDIT_LOG_FOR_HIGHLIGHT',
  SET_TRANSLATED_AUDIT_LOGS_FOR_HIGHLIGHT: 'SET_TRANSLATED_AUDIT_LOGS_FOR_HIGHLIGHT',
  CLEAR_TRANSLATED_AUDIT_LOGS_FOR_HIGHLIGHT: 'CLEAR_TRANSLATED_AUDIT_LOGS_FOR_HIGHLIGHT',
  OPEN_REVIEW_PROMOTION_TABLE: 'PromoActions/OPEN_REVIEW_PROMOTION_TABLE',
  CLOSE_REVIEW_PROMOTION_TABLE: 'PromoActions/CLOSE_REVIEW_PROMOTION_TABLE',
  OPEN_IMAGE_REVIEW_PROMOTION_TABLE: 'PromoActions/OPEN_IMAGE_REVIEW_PROMOTION_TABLE',
  CLOSE_IMAGE_REVIEW_PROMOTION_TABLE: 'PromoActions/CLOSE_IMAGE_REVIEW_PROMOTION_TABLE',
  ADD_SEARCH_COLUMN: 'PromoActions/ADD_SEARCH_COLUMN',
  ADD_SEARCH_COLUMNS: 'PromoActions/ADD_SEARCH_COLUMNS',
  CLEAR_SEARCH: 'PromoActions/CLEAR_SEARCH',
  REMOVE_COLUMNS_FROM_SEARCH: 'PromoActions/REMOVE_COLUMNS_FROM_SEARCH',
  PRESS_UNDO: 'PRESS_UNDO',
  OPEN_ADD_ROW_MODAL: 'OPEN_ADD_ROW_MODAL',
  CLOSE_ADD_ROW_MODAL: 'CLOSE_ADD_ROW_MODAL',
  SET_COLOR_LEGEND_MODAL_OPEN: 'SET_COLOR_LEGEND_MODAL_OPEN',
  ON_SELECT_ROW: 'ON_SELECT_ROW',
  ON_RESET_SELECTED_ROWS: 'ON_RESET_SELECTED_ROWS',
  SET_CONTENT_WRAPPING: 'SET_CONTENT_WRAPPING',
  SET_MUST_FILL_HIGHLIGHT: 'SET_MUST_FILL_HIGHLIGHT',
});
export const ThirdPartyIFrameActions = Object.freeze({
  SET_DESTINATION: 'ThirdPartyIFrame/SET_DESTINATION',
  ENABLE_EXPERIENCE: 'ThirdPartyIFrame/ENABLE_EXPERIENCE',
  CLOSE: 'ThirdPartyIFrame/CLOSE',
  OPEN_COMPOSER: 'ThirdPartyIFrame/OPEN_COMPOSER',
  SET_PROMOTION_ID: 'ThirdPartyIFrame/SET_PROMOTION_ID',
  COMPOSER_EVENT: 'ThirdPartyIFrameEvent',
});
export const MessageEventTypes = Object.freeze({
  COMPOSER: 'Composer',
});
export const ComposerUrls = Object.freeze({
  PROD: 'https://composer.corp.amazon.com/',
  DEV: 'https://composer-gamma.corp.amazon.com/',
});
export const LandingTypes = Object.freeze({
  COMPOSER: 'composer',
});
export const MovementDirections = Object.freeze({
  UP: 'UP',
  DOWN: 'DOWN',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
  TAB: 'TAB',
  SHIFT_TAB: 'SHIFT_TAB',
});
export const InputTypes = Object.freeze({
  UNEDITABLE: 'UNEDITABLE',
  INPUT: 'INPUT',
  TEXT_AREA_INPUT: 'TEXT_AREA_INPUT',
  SINGLE_SELECT: 'SINGLE_SELECT',
  SINGLE_CHECKBOX: 'SINGLE_CHECKBOX',
  MULTI_SELECT: 'MULTI_SELECT',
  BADGE_TREE_SELECT: 'BADGE_TREE_SELECT',
  BADGE_MULTI_SELECT: 'BADGE_MULTI_SELECT',
  TAG_INPUT: 'TAG_INPUT',
  ASIN_TAG_INPUT: 'ASIN_TAG_INPUT',
  ASIN_INPUT: 'ASIN_INPUT',
  DATE_TIME: 'DATE_TIME',
  TIME_INPUT: 'TIME_INPUT',
  NUMBER_INPUT: 'NUMBER_INPUT',
  PERCENT_INPUT: 'PERCENT_INPUT',
  SINGLE_BUTTON: 'SINGLE_BUTTON',
});
export const defaultSortColumns = Object.freeze({
  CREATED_AT: 'createdAt',
});
export const Columns = Object.freeze({
  ASINS: createColumn('asins', 'ASINs', InputTypes.ASIN_INPUT),
  ASIN_IMAGE: createColumn('asinImage', 'ASIN Image', InputTypes.INPUT),
  CIRCULAR_PRICE: createColumn('circularPrice', 'Circular Price', InputTypes.INPUT),
  CIRCULAR_PRICE_DISPLAY: createColumn('circularPriceDisplay', 'Circular Price Display', InputTypes.UNEDITABLE),
  DESCRIPTION: createColumn('description', 'Description', InputTypes.TEXT_AREA_INPUT),
  DISCOUNT_TAG: createColumn('discountTag', 'Discount Tag', InputTypes.SINGLE_SELECT),
  EACH_PRICE_FOR_F3RS: createColumn('eachPriceForF3RS', 'Each price for F3RS', InputTypes.INPUT),
  IMAGE_STATUS: createColumn('imageStatus', 'Image Status', InputTypes.UNEDITABLE),
  IS_APLENTY: createColumn('isAplenty', 'Is Aplenty?', InputTypes.SINGLE_CHECKBOX),
  IS_FRESH_BRAND: createColumn('isFreshBrand', 'Is Fresh Brand?', InputTypes.SINGLE_CHECKBOX),
  IS_CATEGORY_HERO: createColumn('isCategoryHero', 'Is Category Hero?', InputTypes.UNEDITABLE),
  IS_COVER_PAGE_HERO: createColumn('isCoverPageHero', 'Is Cover Page Hero?', InputTypes.UNEDITABLE),
  IS_LOCAL: createColumn('isLocal', 'Is Local?', InputTypes.SINGLE_CHECKBOX),
  IS_PRIME_BENEFIT: createColumn('isPrimeBenefit', 'Is Prime?', InputTypes.SINGLE_CHECKBOX),
  IS_PRIVATE_BRAND: createColumn('isPrivateBrand', 'Is Private Brand?', InputTypes.UNEDITABLE),
  IS_SOFT_DELETED: createColumn('isSoftDeleted', 'Is Soft Deleted?', InputTypes.SINGLE_CHECKBOX),
  IS_USDA_CHOICE: createColumn('isUSDAChoice', 'Is USDA Choice?', InputTypes.SINGLE_CHECKBOX),
  IS_USDA_ORGANIC: createColumn('isUSDAOrganic', 'Is USDA Organic?', InputTypes.SINGLE_CHECKBOX),
  IS_VENDOR_FUNDED: createColumn('isVendorFunded', 'Is Vendor Funded?', InputTypes.SINGLE_CHECKBOX),
  NOTES: createColumn('notes', 'Notes', InputTypes.TEXT_AREA_INPUT),
  PAGE: createColumn('page', 'Page', InputTypes.UNEDITABLE),
  PAWS_ID: createColumn('pawsId', 'PAWS ID', InputTypes.INPUT),
  PERCENT_FUNDED: createColumn('percentFunded', 'Percent Funded', InputTypes.PERCENT_INPUT),
  PICTURED_ASINS: createColumn('picturedAsins', 'Pictured Asins', InputTypes.BADGE_TREE_SELECT),
  PRICE_CONDITIONS: createColumn('priceConditions', 'Price Conditions', InputTypes.SINGLE_SELECT),
  PRIME_DISCOUNT: createColumn('primeDiscount', 'Prime Discount', InputTypes.PERCENT_INPUT),
  // https://issues.amazon.com/issues/WWGSMTFR-46 - requested to change the column name from "prime label" to "prime price"
  // Instead of creating and migrating to a new column, I'm just choosing to change the display string.
  // Also needs to be modified in the metadata table directly - @hanwooll
  // TODO: Rename this column to "primePrice" in the frontend, backend (lambdas,cradle,DDB via script) & metadata [https://issues.amazon.com/issues/P198326941]
  PRIME_MEMBER_LABEL: createColumn('primeMemberLabel', 'Prime Price', InputTypes.INPUT),
  PRIME_MEMBER_LABEL_DISPLAY: createColumn('primeMemberLabelDisplay', 'Prime Price Display', InputTypes.UNEDITABLE),
  PRODUCT_CATEGORY: createColumn('productCategory', 'Product Category', InputTypes.SINGLE_SELECT),
  PROMOTION_END_DATE: createColumn('promotionEndDate', 'Promotion End Date', InputTypes.DATE_TIME),
  PROMOTION_TYPE: createColumn('promotionType', 'Promotion Type', InputTypes.SINGLE_SELECT),
  STATUS: createColumn('status', 'Status', InputTypes.SINGLE_SELECT),
  STORE_IDS: createColumn('storeIds', 'Store IDs', InputTypes.INPUT),
  STORE_REGIONS: createColumn('storeRegions', 'Store Regions', InputTypes.BADGE_TREE_SELECT),
  TITLE: createColumn('title', 'Title', InputTypes.INPUT),
  UNIT_OF_MEASURE: createColumn('unitOfMeasure', 'Unit of Measure', InputTypes.SINGLE_SELECT),
  VERTICAL: createColumn('vertical', 'Vertical', InputTypes.UNEDITABLE),
  VENDOR_MANAGER: createColumn('vendorManager', 'VM Owner', InputTypes.UNEDITABLE),
  ZONE_NUMBER: createColumn('zoneNumber', 'Zone #', InputTypes.UNEDITABLE),

  // Virtual fields
  ID: createColumn('id', 'Promotion ID', InputTypes.INPUT),
  TEMPLATE_ID: createColumn('templateId', 'Template ID', InputTypes.SINGLE_SELECT),
  START_DATE: createColumn('startDate', 'Start Date', InputTypes.DATE_TIME),
  END_DATE: createColumn('endDate', 'End Date', InputTypes.DATE_TIME),
  YEAR_QUARTER: createColumn('yearQuarter', 'Year Quarter', InputTypes.SINGLE_SELECT),
  REGIONS: createColumn('regions', 'Regions', InputTypes.BADGE_MULTI_SELECT),
  AUDITED_STORE_REGIONS: createColumn('auditedStoreRegions', 'Audited Store Regions', InputTypes.UNEDITABLE),
  USERNAME: createColumn('username', 'Username', InputTypes.UNEDITABLE),
  MODIFICATION_TIMESTAMP: createColumn('modificationTimestamp', 'Modified At', InputTypes.UNEDITABLE),
  MODIFICATION_TYPE: createColumn('modificationType', 'Modification Type', InputTypes.UNEDITABLE),
  IS_FEATURED_ASIN: createColumn('isFeaturedAsin', 'Is Featured ASIN?', InputTypes.SINGLE_CHECKBOX),
  IS_AVAILABLE: createColumn('isAvailable', 'Is Available?', InputTypes.SINGLE_CHECKBOX),
});
export const PromotionWeekColumns = Object.freeze({
  EDIT_DEADLINE: createColumn('editDeadline', 'Edit Deadline', InputTypes.DATE_TIME),
  REVIEW_DEADLINE: createColumn('reviewDeadline', 'Review Deadline', InputTypes.DATE_TIME),
  PRINT_DEADLINE: createColumn('printDeadline', 'Print Deadline', InputTypes.DATE_TIME),
  YEAR_QUARTER: createColumn('yearQuarter', 'Year Quarter', InputTypes.SINGLE_SELECT),
  START_DATE: createColumn('startDate', 'Start Date', InputTypes.DATE_TIME),
  END_DATE: createColumn('endDate', 'End Date', InputTypes.DATE_TIME),
  NAME: createColumn('name', 'Promo Week Name', InputTypes.INPUT),
  TEMPLATE_ID: createColumn('templateId', 'Template ID', InputTypes.SINGLE_SELECT),
  TEMPLATE_NAME: createColumn('templateName', 'Template Name', InputTypes.SINGLE_SELECT),

  // Virtual fields
  USERNAME: createColumn('username', 'Username', InputTypes.UNEDITABLE),
  MODIFICATION_TIMESTAMP: createColumn('modificationTimestamp', 'Modified At', InputTypes.UNEDITABLE),
  MODIFICATION_TYPE: createColumn('modificationType', 'Modification Type', InputTypes.UNEDITABLE),
});
export const ImageReviewColumnOrder = [
  Columns.PAGE,
  Columns.ZONE_NUMBER,
  Columns.IMAGE_STATUS,
  Columns.VENDOR_MANAGER,
  Columns.ASINS,
  Columns.ASIN_IMAGE,
  Columns.TITLE,
  Columns.DESCRIPTION,
  Columns.DISCOUNT_TAG,
  Columns.IS_PRIME_BENEFIT,
];
// TODO: will be cleaned up when business and everything related to it is removed
export const WFMColumns = Object.freeze({
  ...Columns,
  // PAGE_ID: createColumn(
  //   Columns.PAGE_ID.name,
  //   Columns.PAGE_ID.display,
  //   InputTypes.INPUT,
  // ),
});
export const FreshDEColumns = Object.freeze({
  ...Columns,
  // SCHEDULER: createColumn(
  //   Columns.SCHEDULER.name,
  //   Columns.SCHEDULER.display,
  //   InputTypes.SINGLE_SELECT,
  // ),
});
export const BulletinBoardColumns = Object.freeze({
  ID: createBBColumn('id', 'ID', InputTypes.INPUT),
  BUSINESS: createBBColumn('business', 'Business', InputTypes.SINGLE_SELECT),
  IS_STICKY: createBBColumn('isSticky', 'Is Sticky?', InputTypes.SINGLE_CHECKBOX),
  IS_GLOBAL: createBBColumn('isGlobalAnnouncement', 'Is Global?', InputTypes.SINGLE_CHECKBOX),
  MESSAGE: createBBColumn('message', 'Message', InputTypes.TEXT_AREA_INPUT),
  START_DATE_TIME: createBBColumn('startDateTime', 'Start Date', InputTypes.DATE_TIME),
  END_DATE_TIME: createBBColumn('endDateTime', 'End Date', InputTypes.DATE_TIME),
  CREATED_AT: createBBColumn('createdAt', 'Created At', InputTypes.DATE_TIME),
});
export const KeyboardKeys = Object.freeze({
  ESCAPE: 'Escape',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
  TAB: 'Tab',
  ENTER: 'Enter',
  Z: 'z',
  C: 'c',
  V: 'v',
  SPACE: ' ',
});
export const Colors = Object.freeze({
  ALTERNATE_SECONDARY: 'alternateSecondary',
  LIGHT_GREY: 'lightgrey',
});
export const Urls = Object.freeze({
  ERROR: '/error',
  METADATA_EDITOR: '/metadata',
  AUDIT_LOG: '/auditlog',
  REDIRECT_LANDING: '/redirectLanding',
  NOTIFICATIONS: '/notifications',
  METADATA_AUDIT_LOG: '/metadata/audit',
  CALENDAR: '/calendar',
  PUBLISHED_PROMOTION_LIST: '/promotions/published-list',
  PROMOTION_WEEK: '/promotions/:startDate\\_:endDate',
  DEPRECATED_PROMOTIONS: '/promotions',
  PROMOTION_EDIT: '/promotion/edit',
  PROMOTION_CLONE: '/promotion/clone',
  WEEK_NEW: '/week/new',
  WEEK_EDIT: '/week/edit',
  WEEK_PUBLISHED: '/week/published',
  TEMPLATES: '/templates',
  TEMPLATE_NEW: '/templates/new',
  TEMPLATE_EDIT: '/templates/edit',
  ASIN_DASHBOARD: '/asin-dashboard',
});
export const ExternalUrls = Object.freeze({
  ASIN_PARSER: 'https://beta.console.harmony.a2z.com/f3-marketing-tools-grease-monkey/asinParser/index.html',
  BROWSER_EXTENSIONS: 'https://w.amazon.com/bin/view/F3_Marketing_Guide/Greasemonkey',
  JSON_TOOL: 'https://console.harmony.a2z.com/izar/',
  COMPOSER: 'https://composer.corp.amazon.com/f3?t=freshMultiTile',
  COMPOSER_EU: 'https://composer.corp.amazon.com/f3?t=FreshEURoto',
  COMPOSER_EU_PRIME_NOW: 'https://composer.corp.amazon.com/f3?t=Tala',
  MARKETING_WIKI: 'https://w.amazon.com/bin/view/F3_Marketing_Guide/',
  MARKETING_WIKI_WFM: 'https://w.amazon.com/bin/view/F3/F3_Retail_Support_SJO/WFM-Team/',
  MARKETING_WIKI_DE: 'https://w.amazon.com/bin/view/F3/Marketing/DE',
  MARKETING_WIKI_JP: 'https://w.amazon.com/bin/view/JP_F3/Marketing_and_Product/Merchandising/MarketingCalendar/',
  REPORT_AN_ISSUE: 'https://t.corp.amazon.com/create/templates/5a86fe2a-d0f4-4c55-a9da-0523cdb9d863',
  REQUEST_A_FEATURE: 'https://issues.amazon.com/issues/create?template=137c1e64-80f0-4f2c-95e1-fbe80e53e3ba',
  BADGE_PHOTO: 'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=',
  GET_ACCESS_LINK: 'https://w.amazon.com/bin/view/WWGS/Marketing/Marketing_Tools/Projects/Circulator#HAccessingtheCirculator:',
});
export const Validation = Object.freeze({
  MIN_LENGTH: 3,
  MAX_LENGTH: 15,
  MAX_SOV_PERCENT: 100,
  COPY_MAX_LENGTH: 40,
  SUB_HEADLINE_MAX_LENGTH: 35,
});
// TODO: rename to Messages
export const Language = Object.freeze({
  VALIDATION_REQUIRED: 'Required',
  VALIDATION_MIN_LENGTH: 'The value isn\'t long enough',
  VALIDATION_MAX_LENGTH: 'The value is too long',
  VALIDATION_INT_ONLY: 'The value must be a number',
  VALIDATION_ASINS_NODE_IDS_BOTH_SET: 'Landing ASINs and Browse Node IDs cannot both be set',
  VALIDATION_MAX_SOV_PERCENT: `Max % cannot exceed ${Validation.MAX_SOV_PERCENT}%`,
  VALIDATION_ACTIVE_WEEK: 'This field should be in the following format: \'2022-12-22, 2022-12-29, other dates\'',
  VALIDATION_START_DATE_ERROR: 'The start date must be before the end date!',
  NO_VALIDATION_AVAILABLE: 'No validation available',
  PROMOTION_ADD_SUCCESS: 'New promotion has been successfully created!',
  PROMOTION_ADD_ERROR: 'Something went wrong.',
  CLICK_HERE_TO_ACCESS_LINK: 'Open link in a new tab',
  CONNECTION_CLOSED: 'Connection to database has been closed, possibly due to inactivity. Your app will not update when other users make changes. Please refresh the page at your earliest convenience.',
  CANNOT_AUTHENTICATE: 'Cannot authenticate user. This may be because the server is not responding or you do not have permissions.',
  AUTHENTICATE_ACCESS_REQUIRED: 'For access to the Circulator, please follow the instructions ',
  AUTHENTICATE_TRY_AGAIN: 'Try logging in again',
  INVALID_DATE: 'Invalid date',
  INVALID_DATE_SUPPLIED: 'Invalid date or time supplied',
  EDIT_VISIBLE_COLUMNS: 'Edit visible columns',
  PROMOTION_FORM_CLICK_TO_AUDIT_LINK: 'Click here to see the latest changes have been done to this promotion',
  PROMOTION_FORM_NOT_FOUND: 'Promotion is not found',
  CARET_PRESENT: 'Caret symbol present in copy. Please do not add caret symbol in the future',
  ACTION_BUTTON_SELECT: 'Select',
  ACTION_BUTTON_EDIT: 'Edit',
  ACTION_BUTTON_CLONE: 'Clone',
  ACTION_BUTTON_DELETE: 'Delete',
  ACTION_BUTTON_RESET: 'Reset',
  ACTION_BUTTON_SOFT_LOCK: 'Soft Delete',
  ACTION_BUTTON_UNDO_SOFT_LOCK: 'Undo Soft Delete',
  ACTION_BUTTON_UPDATE_STATUS: 'Update Status',
  ACTION_BUTTON_AUDIT_LOG: 'Audit log',
  ACTION_BUTTON_VIEW: 'View',
  ACTION_BUTTON_COMPOSER: 'Composer',
  ACTION_BUTTON_COMMENTS: 'Comments',
  ACTION_BUTTON_ROLLBACK: 'Rollback Promotion',
  METADATA_AUDIT_LOGS_ERROR_PARSING: 'Error fetching or processing MetadataAuditLogs',
  ACCESS_CONTROL_TITLE: 'Permissions error',
  ACCESS_CONTROL_MESSAGE: 'Access to this page has been limited.',
  APPROVE_PROMOTION_CONTEXT_MENU_ITEM: 'Approve pending changes for this row',
  REJECT_PROMOTION_CONTEXT_MENU_ITEM: 'Reject pending changes for this row',
  HISTORY_CONTEXT_MENU_ITEM: 'History',
  FLASH_FILL_CONTEXT_MENU_ITEM: 'Flash fill all related rows',
  FLASH_FILL_CONFIRMATION_MSG: 'Are you sure you want to flash fill related cells with the same value?',
  ON_MODAL_FORM_CLOSE_CONFIRMATION: 'Are you sure you want to close the form? All of your unsaved changes will be lost.',
  AUDIT_LOG_ERROR_FETCHING: 'Cannot fetch Audit log. Please check if Promotion ID is correct!',
  GENERIC_ON_DELETE_CONFIRMATION: 'Are you sure you want to delete it? This action cannot be undone.',
  NO_ASINS_TO_CREATE: 'Error. No ASINs to create!',
  PROMOTION_IMAGE_REVIEW_REQUIRED_MESSAGE_TITLE: 'You have #{promotionsCount} images to review',
  PROMOTION_NOT_FOUND: 'Promotion is not found',
  PROMOTION_IMAGE_REVIEW_APPROVE: 'Are you sure you want to Confirm this promotion\'s image?',
  PROMOTION_IMAGE_APPROVED_SUCCESS_MESSAGE: 'Image has been approved!',
  PROMOTION_IMAGE_APPROVED_FAIL_MESSAGE: 'Error while approving the Promotion image!',
  PROMOTION_REVIEW_REQUIRED_MESSAGE_TITLE: 'You have #{promotionsCount} promotions to review',
  PROMOTION_REVIEW_APPROVE: 'Are you sure you want to Approve this promotion\'s changes?',
  PROMOTION_REVIEW_REJECT: 'Are you sure you want to Reject this promotion\'s changes? It will be rolled back to its last approved revision.',
  PROMOTION_EXTRA_REGIONS_ERROR_MESSAGE: 'There are extra regions scheduled for this page zone: #{extraStoreRegions}',
  PROMOTION_MISSING_REGIONS_ERROR_MESSAGE: 'There are regions not yet scheduled for this page zone: #{missingStoreRegions}',
  PROMOTION_UNAVAILABLE_REGIONS_ERROR_MESSAGE: '#{unavailableStoreRegions} no longer available',
  PROMOTION_APPROVED_SUCCESS_MESSAGE: 'Promotion has been approved!',
  PROMOTION_APPROVED_FAIL_MESSAGE: 'Error while approving the Promotion!',
  PROMOTION_REJECTED_SUCCESS_MESSAGE: 'Promotion has been rejected!',
  PROMOTION_REJECTED_FAIL_MESSAGE: 'Error while rejecting the Promotion!',
  PROMOTION_EDIT_OVERRIDE_ON: 'Edits temporarily enabled',
  PROMOTION_EDIT_OVERRIDE_OFF: 'Edits disabled',
  ASIN_VALIDATOR_EXPORT_ERROR_NO_DATA: 'Nothing to export!',
  CIRCULAR_PRICE_TYPE_CHANGE_MESSAGE_TITLE: 'Circular price warning',
  CIRCULAR_PRICE_TYPE_CHANGE_MESSAGE_BODY: `${Columns.CIRCULAR_PRICE.display} has been changed from ${PromotionTypes.POS.display} to ${PromotionTypes.FIXED_PRICE.display}. Only numeric input is supported now.`,
  AUDIT_STORE_REGIONS_UPDATED_DAILY: 'The regions will be validated every day starting tomorrow',
  CLIPBOARD_COPY_SUCCESS: 'Copied contents to clipboard',
  CLIPBOARD_COPY_ERROR: 'Error! Cannot copy selected content (check clipboard permissions in your browser)',
  PROMOTION_PASTE_ERROR_UNEDITABLE: 'Paste is not possible as some of the promotions (#{count}) you\'re pasting are in \'#{status}\' status or have been deleted!',
  PROMOTION_PASTE_ERROR_NOT_ALLOWED_PAGE_ZONE_NUMBER_COMBINATIONS: 'Paste is not possible as some of the promotions (#{count}) you\'re pasting have invalid \'Page/Zone Number\' combinations (#{invalidCombinations})',
  PROMOTION_PASTE_ERROR_PROMOTION_WEEK_PAST_DEADLINE: 'The editing deadline (#{editDeadline}) for this promotion week has passed. Pasting isn\'t possible.',
  PROMOTION_SUCCESS_COPIED_TO_CLIPBOARD: 'Selected promotions (#{count}) have been copied to the clipboard!',
  ASIN_VIABILITY_REPORT_NO_ASINS_ERROR: 'ASIN viability reports cannot be generated as there are no ASINs in this promotion week.',
  FORM_SAVE_BUTTON_DISABLED_STATUS_LOCK: `Save disabled because the promotions is in '#{status}' status`,
  FORM_SAVE_BUTTON_DISABLED_SOFT_DELETED: 'Save disabled because the promotion has been soft deleted',
  FORM_SAVE_BUTTON_DISABLED_FORM_SUBMITTING: 'Save disabled because the form is being submitted now',
  NO_PICTURED_ASINS: 'No pictured asin selected',
  PUBLISH_PROMOTIONS_REQUEST_FAILED: 'FAILED</br>If the error message mentions Timeout, please try couple more times. Otherwise, please alert our oncall from <a href={"https://oncall.corp.amazon.com/#/view/f3marketingtools/schedule"}>https://oncall.corp.amazon.com/#/view/f3marketingtools/schedule</a> by cutting a <a href={"https://t.corp.amazon.com/create/templates/5a86fe2a-d0f4-4c55-a9da-0523cdb9d863"}>ticket</a> with the below error message copied.</br></br>#{response}',
  PUBLISH_PROMOTIONS_REQUEST_SUCCESS: 'SUCCESS</br>Please Check Digital Circular pages from <a href={"https://www.amazon.com/fmc/m/30003175?almBrandId=QW1hem9uIEZyZXNo""}>https://www.amazon.com/fmc/m/30003175?almBrandId=QW1hem9uIEZyZXNo</a> to confirm',
  PUBLISH_PROMOTIONS_MESSAGE: 'Warning: This will overwrite existing Digital Circular page with current promotion week\'s data.',
});
export const PopOverDirection = Object.freeze({
  UP: 'UP',
  DOWN: 'DOWN',
});
export const Comparators = Object.freeze({
  EQUALS: 'Equals',
  DOES_NOT_EQUAL: 'Does not equal',
  GREATER_THAN: 'Greater Than',
  LESS_THAN: 'Less Than',
  CONTAINS: 'Contains',
  DOES_NOT_CONTAIN: 'Does not contain',
  EMPTY: 'Is blank',
  NOT_EMPTY: 'Is not blank',
});
export const DeployLocations = Object.freeze({
  DEV: 'Dev',
  BETA: 'Beta',
  PROD: 'Prod',
});
export const Hosts = Object.freeze({
  DEV: 'localhost:3000',
  BETA: 'dxo6al5rf0jpe.cloudfront.net',
  PROD: 'd38oh34gbhbjrk.cloudfront.net',
  PROD_READABLE: 'circulator.f3.amazon.dev',
});
export const DashboardActions = Object.freeze({
  INIT: 'DASHBOARD/INIT',
  SET_DATE_RANGE: 'DASHBOARD/SET_DATE_RANGE',
  RUN_CALC: 'DASHBOARD/RUN_CALC',
  SET_DATA: 'DASHBOARD/SET_DATA',
  SET_START_TIME: 'DASHBOARD/SET_START_TIME',
  SET_END_TIME: 'DASHBOARD/SET_END_TIME',
  TOGGLE_MODE: 'DASHBOARD/TOGGLE_MODE',
});
export const BusinessTypes = Object.freeze({
  FRESH: {
    name: 'Fresh',
    label: 'Fresh US',
  },
  WFM: {
    name: 'WFM',
    label: 'WFM US',
  },
  FRESH_SG: {
    name: 'Fresh SG',
    label: 'Fresh SG',
  },
  FRESH_UK: {
    name: 'Fresh UK',
    label: 'Fresh UK',
  },
  FRESH_DE: {
    name: 'Fresh DE',
    label: 'Fresh DE',
  },
  NA_3P: {
    name: '3P NA',
    label: '3P NA',
  },
  FRESH_ES: {
    name: 'Fresh ES',
    label: 'Fresh ES',
  },
  FRESH_IT: {
    name: 'Fresh IT',
    label: 'Fresh IT',
  },
  FR_3P: {
    name: '3P FR',
    label: '3P FR',
  },
  UK_3P: {
    name: '3P UK',
    label: '3P UK',
  },
  IT_3P: {
    name: '3P IT',
    label: '3P IT',
  },
  ES_3P: {
    name: '3P ES',
    label: '3P ES',
  },
  DE_3P: {
    name: '3P DE',
    label: '3P DE',
  },
  FRESH_JP: {
    name: 'Fresh JP',
    label: 'Fresh JP',
  },
  JP_3P: {
    name: '3P JP',
    label: '3P JP',
  },
});
export const SeeMoreTexts = Object.freeze({
  [BusinessTypes.FRESH_UK.name]: 'Browse all',
  [BusinessTypes.FRESH_DE.name]: 'Mehr entdecken',
  [BusinessTypes.FRESH_ES.name]: 'Ver más',
  [BusinessTypes.FRESH_IT.name]: 'Scopri di più',
  [BusinessTypes.FRESH_JP.name]: 'もっと見る',
  [BusinessTypes.UK_3P.name]: 'Browse all',
  [BusinessTypes.DE_3P.name]: 'Mehr entdecken',
  [BusinessTypes.ES_3P.name]: 'Ver más',
  [BusinessTypes.IT_3P.name]: 'Scopri di più',
  [BusinessTypes.FR_3P.name]: 'Voir plus',
  [BusinessTypes.JP_3P.name]: 'もっと見る',
});
export const WeblabTreatmentTypes = Object.freeze({
  C: 'C',
  T1: 'T1',
  T2: 'T2',
});
export const RegionTypes = Object.freeze({
  NA: 'Region/NA',
  DE: 'Region/DE',
  EU: 'Region/EU',
  FE: 'Region/FE',
});
export const LinkTypes = Object.freeze({
  BULLSEYE: 'BULLSEYE',
  WEBLAB: 'WEBLAB',
  WIKI: 'WIKI',
});
export const Channel = Object.freeze({
  PRIME_NOW: 'Prime Now',
});
export const BusinessSelector = Object.freeze({
  BUSINESS_TYPE: 'BUSINESS_TYPE',
});
export const YearQuarterSelector = Object.freeze({
  YEAR_QUARTER_SELECTED: 'YEAR_QUARTER_SELECTED',
});
// These keys are used as display names, so do not follow CAPS_CASE
export const SortOrder = Object.freeze({
  ASCENDING: {
    label: 'Ascending',
    value: 'a',
  },
  DESCENDING: {
    label: 'Descending',
    value: 'd',
  },
});
export const CURRENT_PAGE = 'CURRENT_PAGE';
export const ALL_PAGES = 'ALL_PAGES';
export const COPY = 'COPY';
export const CSV = 'CSV';
// TODO: Group all these params under one ENUM
export const DASH_DELIMETER = '-';
export const UNDERSCORE_DELIMETER = '_';
export const COMMA_DELIMETER = ',';
export const DOT_DELIMETER = '.';
export const NEWLINE_DELIMETER = '\n';
export const TAB_DELIMETER = '\t';
export const NON_BREAKING_SPACE = '\u00a0';
export const CARET = '›';
// TODO: make this a setting parameter
// TODO: retranslate all promotions when delimter changes
export const ASIN_DELIMETER = COMMA_DELIMETER;
export const UTC_TIMEZONE_ID = 'UTC';
export const DEFAULT_TIMEZONE_ID = 'America/Los_Angeles';
// TODO: will be removed along with business
export const CampaignStatuses = Object.freeze({
  APPROVED: 'Approved',
  CANCELED: 'Canceled',
  HOLD: 'Hold',
  COPY_REVISION_NEEDED: 'Copy Revision Needed',
  INPUTS_COMPLETE: 'Inputs Complete',
  MISSING_INPUTS: 'Missing Inputs',
  STOREFRONT_READY: 'Storefront Ready',
  NEW_EDITS: 'New Edits',
  APPROVED_IN_STORE: 'Approved - In-Store',
  INPUTS_COMPLETE_IN_STORE: 'Inputs Complete - In-Store',
});
export const getDefaultFormRequiredFields = () => {
  return [];
};

export const ASINColumns = [
  Columns.ASINS.name,
];
export const FormActions = Object.freeze({
  CHANGE: '@@redux-form/CHANGE',
  BLUR: '@@redux-form/BLUR',
  SET_SUBMIT_SUCCEEDED: '@@redux-form/SET_SUBMIT_SUCCEEDED',
  SET_SUBMIT_FAILED: '@@redux-form/SET_SUBMIT_FAILED',
  DESTROY: '@@redux-form/DESTROY',
});
export const SovTypes = Object.freeze({
  SOV: 'SOV',
  TARGETED_SOV: 'Targeted SOV',
  RANDOM: 'Random',
  DEFAULT: 'Default',
});
export const SovLimits = Object.freeze({
  SOV_FULL: 100,
});
export const FeatureNotificationActions = Object.freeze({
  LOAD: 'LOAD',
  ADD_BULK: 'ADD_BULK',
  CLOSE: 'CLOSE',
  CLOSE_BULK: 'CLOSE_BULK',
});
export const ControlsWithHintLinks = [];
// TODOL remove it too
export const FilterGroupTypes = Object.freeze({
  AND: 'AND',
  OR: 'OR',
});
export const DateIntervals = Object.freeze({
  ONE_DAY: 1,
  ONE_WEEK: 7,
});
export const MetadataEditorActions = Object.freeze({
  SELECT_FIELD: 'METADATA_EDITOR_SELECT_FIELD',
  SET_DEPENDENCIES: 'METADATA_EDITOR_SET_DEPENDENCIES',
  SET_DEPENDENT_OPTION: 'METADATA_EDITOR_SET_DEPENDENT_OPTION',
  ADD_OPTION: 'METADATA_EDITOR_ADD_OPTION',
  ADD_OPTION_FOR_FIELD: 'METADATA_EDITOR_ADD_OPTION_FOR_FIELD',
  DELETE_OPTION: 'METADATA_EDITOR_DELETE_OPTION',
  DELETE_OPTION_FOR_FIELD: 'METADATA_EDITOR_DELETE_OPTION_FOR_FIELD',
  QUICK_CREATE_MODAL_SAVE: 'METADATA_EDITOR_QUICK_CREATE_MODAL_SAVE',
});
export const BulletinBoardActions = Object.freeze({
  LOAD: 'BulletinBoardActions/LOAD',
  SET: 'BulletinBoardActions/SET',
  DELETE: 'BulletinBoardActions/DELETE',
  ADD: 'BulletinBoardActions/ADD',
  EDIT: 'BulletinBoardActions/EDIT',
});
export const BulletinBoard = Object.freeze({
  LIMIT_MAX_ANNOUNCEMENTS: 10,
});
export const ColorCodes = Object.freeze({
  RED: 'red',
  GREEN: 'green',
  ORANGE: 'orange',
  BLUE: 'blue',
});
export const AuditLogActions = Object.freeze({
  FETCH: 'AuditLogActions/FETCH',
  CLEAR: 'AuditLogActions/CLEAR',
  ROLLBACK: 'AuditLogActions/ROLLBACK',
  SET_LOADING: 'AuditLogActions/SET_LOADING',
  SET_LOADED: 'AuditLogActions/SET_LOADED',
  SET_ENTITY: 'AuditLogActions/SET_ENTITY',
  SET_AUDIT_LOG_TYPE: 'AuditLogActions/SET_AUDIT_LOG_TYPE',
  SET_ENTITY_DATA: 'AuditLogActions/SET_ENTITY_DATA',
  SET_RAW_ENTITY_DATA: 'AuditLogActions/SET_RAW_ENTITY_DATA',
  CLEAR_RAW_ENTITY_DATA: 'AuditLogActions/CLEAR_RAW_ENTITY_DATA',
});
export const CustomViewActions = Object.freeze({
  SET_COLUMN_ORDER: 'CustomViewActions/SET_COLUMN_ORDER',
  LOCAL_STORAGE_COLUMNS: `CustomViewActions/CUSTOM_VIEW_COLUMNS_${PROGRAM_NAME}`,
  LOCAL_STORAGE_SETTINGS: `CustomViewActions/CUSTOM_VIEW_SETTINGS_${PROGRAM_NAME}`,
});
export const NotificationsActions = Object.freeze({
  INIT: 'NotificationsActions/INIT',
  LOAD: 'NotificationsActions/LOAD',
  SET: 'NotificationsActions/SET',
  SORT: 'NotificationsActions/SORT',
  SET_LOADING: 'NotificationsActions/SET_LOADING',
  SET_PAGE_NUMBER: 'NotificationsActions/SET_PAGE_NUMBER',
  START_POLLING: 'NotificationsActions/START_POLLING',
  SET_NOTIFICATIONS_LOADED: 'NotificationsActions/SET_NOTIFICATIONS_LOADED',
  SET_NEW_NOTIFICATIONS_COUNT: 'NotificationsActions/SET_NEW_NOTIFICATIONS_COUNT',
  UPDATE_NEW_NOTIFICATIONS_COUNT: 'NotificationsActions/UPDATE_NEW_NOTIFICATIONS_COUNT',
  OPEN_WIDGET: 'NotificationsActions/OPEN_WIDGET',
  CLOSE_WIDGET: 'NotificationsActions/CLOSE_WIDGET',
  INIT_LAST_SEEN: 'NotificationsActions/INIT_LAST_SEEN',
  CREATE_LAST_SEEN_AT: 'NotificationsActions/CREATE_LAST_SEEN_AT',
  UPDATE_LAST_NOTIFICATION_RECEIVED_TIME: 'NotificationsActions/UPDATE_LAST_NOTIFICATION_RECEIVED_TIME',
  ENABLE_EXPERIENCE: 'NotificationsActions/ENABLE_EXPERIENCE',
  MARK_AS: 'NotificationsActions/MARK_AS',
  STOP_POLLING: 'NotificationsActions/STOP_POLLING',
  OPEN: 'NotificationsActions/OPEN',
  MARK: 'NotificationsActions/MARK',
  COMMIT_MARKED: 'NotificationsActions/COMMIT_MARKED',
  RESET_MARKED: 'NotificationsActions/RESET_MARKED',
  SET_UPDATING: 'NotificationsActions/SET_UPDATING',
});
export const NotificationsTypes = Object.freeze({
  CREATE: 'createCampaign',
  UPDATE: 'updateCampaign',
  DELETE: 'deleteCampaign',
  MENTION: 'mention',
});
export const NotificationsConfig = Object.freeze({
  NOTIFICATIONS_LIMIT: 5000,
  DEFAULT_DAYS_LIMIT: 14,
  DEFAULT_MINUTES_LIMIT: 1,
  NOTIFICATIONS_DISPLAY_LIMIT_WIDGET: 15,
  POLLING_INTERVAL: 30000,
  CHECK_POLLING_INTERVAL: 5000,
  // TODO: change it back
  PAGINATION_ITEMS_PER_PAGE: 50,
});
export const NotificationsPanelList = Object.freeze({
  MARK_AS_READ: { name: 'Read', label: 'MARK_AS_READ' },
  MARK_AS_UNREAD: { name: 'Unread', label: 'MARK_AS_UNREAD' },
});
export const RouterActions = Object.freeze({
  LOCATION_CHANGE: '@@router/LOCATION_CHANGE',
});
export const CommentActions = Object.freeze({
  LOAD: 'CommentActions/LOAD',
  SET: 'CommentActions/SET',
  SORT: 'CommentActions/SORT',
  ADD: 'CommentActions/ADD',
  DELETE: 'CommentActions/DELETE',
  SET_POSTING: 'CommentActions/SET_POSTING',
  TOGGLE_AUTO_SCROLLING: 'CommentActions/SET_AUTO_SCROLLING',
  RESET: 'CommentActions/RESET',
  START_POLLING: 'CommentActions/START_POLLING',
  STOP_POLLING: 'CommentActions/STOP_POLLING',
  SET_ACTIVE_CAMPAIGN_ID: 'CommentActions/SET_ACTIVE_CAMPAIGN_ID',
  OPEN_MODAL: 'CommentActions/OPEN_MODAL',
  CLOSE_MODAL: 'CommentActions/CLOSE_MODAL',
});
export const CommentsConfig = Object.freeze({
  LOAD_LIMIT: 1000,
  POLLING_INTERVAL: 30000,
});
export const CommentsValidation = Object.freeze({
  MIN_LENGTH: 2,
  MAX_LENGTH: 3000,
});
export const DateFormats = Object.freeze({
  MM_DD_YY_hh_mma: 'MM/DD/YY hh:mma',
  YYYY_MM_DD: 'YYYY-MM-DD',
  MM_DD: 'MM/DD',
});
export const MetadataAuditLogActions = Object.freeze({
  METADATA_AUDIT_LOGS_RECEIVED: 'MetadataAuditLogActions/METADATA_AUDIT_LOGS_RECEIVED',
  ADD_LOG: 'MetadataAuditLogActions/ADD_LOG',
  CLEAR_DATA: 'MetadataAuditLogActions/CLEAR_DATA',
  SET_FILTER_STRING: 'MetadataAuditLogActions/SET_FILTER_STRING',
});
export const TabsActions = Object.freeze({
  SET_TAB: 'TabsActions/SET_TAB',
  RESET_TAB: 'TabsActions/RESET_TAB',
  RESET_TABS: 'TabsActions/RESET_TABS',
});
export const PromoActions = Object.freeze({
  ADD_PROMOTION: 'PromoActions/ADD_PROMOTION',
  ADDED_PROMOTION_RECEIVED: 'PromoActions/ADDED_PROMOTION_RECEIVED',
  UPDATE_PROMOTION: 'PromoActions/UPDATE_PROMOTION',
  UPDATED_PROMOTION_RECEIVED: 'PromoActions/UPDATED_PROMOTION_RECEIVED',
  DELETE_PROMOTION: 'PromoActions/DELETE_PROMOTION',
  DELETED_PROMOTION_RECEIVED: 'PromoActions/DELETED_PROMOTION_RECEIVED',
  SET_LOADING: 'PromoActions/SET_LOADING',
  SET_DATES_RANGE: 'PromoActions/SET_DATES_RANGE',
  CREATE_PROMOTIONS: 'PromoActions/CREATE_PROMOTIONS',
  RESET_PROMOTION: 'PromoActions/RESET_PROMOTION',
  SOFT_DELETE_PROMOTION: 'PromoActions/SOFT_DELETE_PROMOTION',
  UPDATE_PROMOTION_STATUS: 'PromoActions/UPDATE_PROMOTION_STATUS',
  OPEN_PROMOTION_STATUS_MODAL: 'PromoActions/OPEN_PROMOTION_STATUS_MODAL',
  CLOSE_PROMOTION_STATUS_MODAL: 'PromoActions/CLOSE_PROMOTION_STATUS_MODAL',
  APPROVE_PROMOTION_IMAGE: 'PromoActions/APPROVE_PROMOTION_IMAGE',
  APPROVE_PROMOTION: 'PromoActions/APPROVE_PROMOTION',
  REJECT_PROMOTION: 'PromoActions/REJECT_PROMOTION',
});
export const PromoWeekActions = Object.freeze({
  ADD_PROMOTION_WEEK: 'PromoWeekActions/ADD_PROMOTION_WEEK',
  ADDED_PROMOTION_WEEK_RECEIVED: 'PromoWeekActions/ADDED_PROMOTION_WEEK_RECEIVED',
  UPDATE_PROMOTION_WEEK: 'PromoWeekActions/UPDATE_PROMOTION_WEEK',
  UPDATED_PROMOTION_WEEK_RECEIVED: 'PromoWeekActions/UPDATED_PROMOTION_WEEK_RECEIVED',
  DELETE_PROMOTION_WEEK: 'PromoWeekActions/DELETE_PROMOTION_WEEK',
  DELETED_PROMOTION_WEEK_RECEIVED: 'PromoWeekActions/DELETED_PROMOTION_WEEK_RECEIVED',
  LOAD_WEEKS_FOR_YEAR_QUARTER: 'PromoWeekActions/LOAD_WEEKS_FOR_YEAR_QUARTER',
  SET_WEEKS_FOR_YEAR_QUARTER: 'PromoWeekActions/SET_WEEKS_FOR_YEAR_QUARTER',
  ADD_ROW: 'PromoWeekActions/ADD_ROW',
});
export const PublishedPromotionActions = Object.freeze({
  LOAD: 'PublishedPromotionActions/LOAD',
  SET: 'PublishedPromotionActions/SET',
  LOAD_AUDIT_LOG: 'PublishedPromotionActions/LOAD_AUDIT_LOG',
  SET_AUDIT_LOG: 'PublishedPromotionActions/SET_AUDIT_LOG',
  RESET_AUDIT_LOG: 'PublishedPromotionActions/RESET_AUDIT_LOG',
});
export const TemplateActions = Object.freeze({
  SELECT_TEMPLATE_FOR_EDITING: 'TemplateActions/SELECT_TEMPLATE_FOR_EDITING',
  SET_ACTIVE_TEMPLATE: 'TemplateActions/SET_ACTIVE_TEMPLATE',
  RESET_ACTIVE_TEMPLATE: 'TemplateActions/RESET_ACTIVE_TEMPLATE',
  LOAD_TEMPLATES: 'TemplateActions/LOAD_TEMPLATES',
  SET_TEMPLATES: 'TemplateActions/SET_TEMPLATES',
  SET_TEMPLATE_NAME: 'TemplateActions/SET_TEMPLATE_NAME',
  SAVE: 'TemplateActions/SAVE',
  DELETE: 'TemplateActions/DELETE',
});
export const AsinValidatorActions = Object.freeze({
  SEND_ASINS: 'AsinValidatorActions/SEND_ASINS',
  SET_ASIN_VIABILITIES: 'AsinValidatorActions/SET_ASIN_VIABILITIES',
  APPROVE_REGIONS: 'AsinValidatorActions/APPROVE_REGIONS',
  SET_OPEN: 'AsinValidatorActions/SET_OPEN',
  SET_LOADING: 'AsinValidatorActions/SET_LOADING',
  SET_DATE: 'AsinValidatorActions/SET_DATE',
  SET_ERRORS: 'AsinValidatorActions/SET_ERRORS',
  RESET: 'AsinValidatorActions/RESET',
  SET_VIEW_MODE: 'AsinValidatorActions/SET_VIEW_MODE',
  SET_ASIN_REGIONS: 'AsinValidatorActions/SET_ASIN_REGIONS',
  CLEAR_REGION_FILTERS: 'AsinValidatorActions/CLEAR_REGION_FILTERS',
  AUTO_SELECT_REGION_FILTERS: 'AsinValidatorActions/AUTO_SELECT_REGION_FILTERS',
  SET_REGION_FILTER: 'AsinValidatorActions/SET_REGION_FILTER',
  INIT_REGION_FILTERS: 'AsinValidatorActions/INIT_REGION_FILTERS',
  EXPORT_FILTERED_REGIONS: 'AsinValidatorActions/EXPORT_FILTERED_REGIONS',
  AUTO_SELECT_AVAILABLE_REGIONS: 'AsinValidatorActions/AUTO_SELECT_AVAILABLE_REGIONS',
  EXPORT_RESULTS: 'AsinValidatorActions/EXPORT_RESULTS',
  SET_ASIN_RECOMMENDATION_MODAL_OPEN: 'AsinValidatorActions/SET_ASIN_RECOMMENDATION_MODAL_OPEN',
});
export const AsinImageActions = Object.freeze({
  GET_PRODUCT_IMAGES: 'AsinImageActions/GET_PRODUCT_IMAGES',
  SET_PRODUCT_IMAGES: 'AsinImageActions/SET_PRODUCT_IMAGES',
  SELECT_IMAGE: 'AsinImageActions/SELECT_IMAGE',
  SET_LOADING: 'AsinImageActions/SET_LOADING',
});
export const LoadingActions = Object.freeze({
  CHECK_LOADING_STATUS: 'LoadingActions/CHECK_LOADING_STATUS',
});
export const ShovelerTypes = Object.freeze({
  BROWSE_NODE: 'Browse Node',
  FIXED_ASIN_LIST: 'Fixed ASIN List',
  SEARCH_QUERY: 'Search Query',
  PERSONALIZATION: 'Personalization',
});
export const DedupeTypes = Object.freeze({
  IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN: 'IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN',
  IGNORE_PREVIOUS_RESERVATIONS: 'IGNORE_PREVIOUS_RESERVATIONS',
  NONE: 'NONE',
});
export const GraphQLLabels = Object.freeze({
  GET_AUDIT_LOGS_FOR_CAMPAIGN: 'getAuditLogsForCampaign',
  GET_AUDIT_LOGS_FOR_PUBLISHED_PROMOTIONS: 'getAuditLogsForPublishedPromotions',
  GET_AUDIT_LOGS_FOR_PROMOTION_WEEK: 'getAuditLogsForPromotionWeek',
  GET_AUDIT_LOGS_FOR_PROMOTION: 'getAuditLogsForPromotion',
  GET_AUDIT_LOGS_FOR_TEMPLATE_BUILDER: 'getAuditLogsForTemplateBuilder',
  CREATE_ANNOUNCEMENT: 'createAnnouncement',
  DELETE_ANNOUNCEMENT: 'deleteAnnouncement',
  EDIT_ANNOUNCEMENT: 'editAnnouncement',
  GET_ANNOUNCEMENT: 'getAnnouncement',
  CREATE_COMMENT: 'createComment',
  DELETE_COMMENT: 'deleteComment',
  GET_USER_SESSION: 'getUserSession',
  CREATE_USER_SESSION: 'createUserSession',
  RENEW_USER_SESSION: 'renewUserSession',
  CREATE_PROMOTION: 'createPromotion',
  GET_PROMOTION: 'getPromotion',
  UPDATE_PROMOTION: 'updatePromotion',
  DELETE_PROMOTION: 'deletePromotion',
});
export const DragAndDropWidget = Object.freeze({
  DATA_TRANSFER_ID: 'item',
});
export const UsageMetrics = Object.freeze({
  NAME_PREFIX: 'USAGE_METRIC',
});
export const CsDiffTypes = Object.freeze({
  NO_CHANGE: 'NO_CHANGE',
  ADD: 'ADD',
  REMOVE: 'REMOVE',
  CHANGE: 'CHANGE',
});
export const CsDiffScreenTabs = Object.freeze({
  ALL: 'ALL',
  CHANGES_ONLY: 'CHANGES_ONLY',
});
export const CsCategoryNames = Object.freeze({
  DESKTOP_PLACEMENT: 'desktopPlacement',
  MOBILE_PLACEMENT: 'mobilePlacement',
  CREATIVE: 'creative',
  CONTENT: 'content',
});
export const CsCategoryNamesToReadableNames = Object.freeze({
  [CsCategoryNames.DESKTOP_PLACEMENT]: 'Desktop Placement',
  [CsCategoryNames.MOBILE_PLACEMENT]: 'Mobile Placement',
  [CsCategoryNames.CREATIVE]: 'Creative',
  [CsCategoryNames.CONTENT]: 'Content',
});
export const PerformanceTimerNames = Object.freeze({
  APP_INIT: 'APP_INIT',
  BULK_IMPORT: 'BULK_IMPORT',
});
export const QueryParseTypes = Object.freeze({
  STRING: 'STRING',
  ARRAY: 'ARRAY',
});
export const AlmBrandIds = {
  [BusinessTypes.FRESH.name]: 'QW1hem9uIEZyZXNo',
  [BusinessTypes.NA_3P.name]: {
    'All The Best Pets': 'QWxsIFRoZSBCZXN0IFBldCBDYXJl',
    'Bartell Drugs': 'QmFydGVsbCBEcnVncw',
    'Bristol Farms': 'YnJpc3RvbCBmYXJtcw',
    'Keg N Bottle': 'S2VnIE4gQm90dGxl',
    'Mission Wine': 'TWlzc2lvbiBMaXF1b3I',
    'Pet Food Express': 'UGV0IEZvb2QgRXhwcmVzcw',
    'Sousa\'s': 'U291c2EncyBXaW5lcyAmIExpcXVvcnM',
    'Surdyk\'s': 'U3VyZHlrJ3MgTGlxdW9yICYgQ2hlZXNlIFNob3A',
  },
  [BusinessTypes.WFM.name]: 'VUZHIFdob2xlIEZvb2Rz',
};
export const SearchAliases = {
  [BusinessTypes.FRESH.name]: 'amazonfresh',
  // I can probably just write a function to lowercase everything and remove the spaces, but
  // I don't want to do that just in case MMs add any irregularities - @hanwooll
  [BusinessTypes.NA_3P.name]: {
    'All The Best Pets': 'allthebestpets',
    'Bartell Drugs': 'bartelldrugs',
    'Bristol Farms': 'bristolfarms',
    'Keg N Bottle': 'kegnbottle',
    'Mission Wine': 'missionwine',
    'Pet Food Express': 'petfoodexpress',
    'Sousa\'s': 'sousa\'s',
    'Surdyk\'s': 'surdky\'s',
  },
  [BusinessTypes.WFM.name]: 'wholefoods',
};
export const MomentJsDayNames = Object.freeze({
  MONDAY: 1,
  WEDNESDAY: 3,
});
export const CheckoutPageType = 'Checkout';
export const PRESERVE_URL_FLAG = 'preserveLocation';
export const PromotionWeekTabsList = Object.freeze({
  WEEK: { name: 'week', label: 'Week' },
  AUDIT_LOG: { name: 'auditLog', label: 'Audit Log' },
});
export const PromotionTabsList = Object.freeze({
  PROMOTION: { name: 'promotion', label: 'Promotion' },
  AUDIT_LOG: { name: 'auditLog', label: 'Audit Log' },
});
export const TemplateBuilderTabsList = Object.freeze({
  TEMPLATE_BUILDER: { name: 'templateBuilder', label: 'Template Builder' },
  AUDIT_LOG: { name: 'auditLog', label: 'Audit Log' },
});
export const WebsiteNavigationTabList = Object.freeze({
  PROMOTION_WEEK: { name: Urls.PROMOTION_WEEK, label: 'Promotions' },
  CALENDAR: { name: Urls.CALENDAR, label: 'Calendar' },
  PUBLISHED_PROMOTION_WEEKS: {
    name: Urls.PUBLISHED_PROMOTION_LIST,
    label: 'Published Promotion Weeks',
  },
});
export const TabTypes = Object.freeze({
  PROMOTION: 'PROMOTION',
  PROMOTION_WEEK: 'PROMOTION_WEEK',
  TEMPLATE_BUILDER: 'TEMPLATE_BUILDER',
  WEBSITE_NAVIGATION: 'WEBSITE_NAVIGATION',
});
export const TemplateBuilderColumnsOrder = [
  Columns.PAGE,
  Columns.ZONE_NUMBER,
  Columns.VENDOR_MANAGER,
  Columns.VERTICAL,
  Columns.PRODUCT_CATEGORY,
  Columns.DISCOUNT_TAG,
  Columns.IS_PRIME_BENEFIT,
  Columns.IS_COVER_PAGE_HERO,
  Columns.IS_CATEGORY_HERO,
];
export const PromotionWeekColumnsOrder = [
  PromotionWeekColumns.USERNAME,
  PromotionWeekColumns.MODIFICATION_TIMESTAMP,
  PromotionWeekColumns.MODIFICATION_TYPE,
  PromotionWeekColumns.YEAR_QUARTER,
  PromotionWeekColumns.START_DATE,
  PromotionWeekColumns.END_DATE,
  PromotionWeekColumns.NAME,
  PromotionWeekColumns.TEMPLATE_ID,
  PromotionWeekColumns.TEMPLATE_NAME,
];

export const AsinValidatorWarehouseStatus = Object.freeze({
  IN_STOCK: { name: 'IN_STOCK', label: 'In stock' },
  LOW_IN_STOCK: { name: 'LOW_IN_STOCK', label: 'Low in stock' },
  OUT_OF_STOCK: { name: 'OUT_OF_STOCK', label: 'Out of stock' },
  NOT_AVAILABLE: { name: 'NOT_AVAILABLE', label: 'N/A' },
});
export const AsinValidatorViabilityParams = Object.freeze({
  PROCURABLE_STATUS: {
    PROCURABLE: 'PROCURABLE',
  },
  INSTORE_BUYABILITY_STATUS: {
    BUYABLE: 'BUYABLE',
  },
  UWICheck: {
    CLEAR: 'Clear',
  },
  VFRCheck: {
    CLEAR: 'Clear',
  },
  PLANOGRAM_STATUS: {
    IN_PLAN: 'In Plan',
  },
});
export const AsinValidatorViewMode = Object.freeze({
  COMPACT: { name: 'COMPACT', label: 'Compact' },
  FULL: { name: 'FULL', label: 'Full' },
});
export const PromoApprovalSteps = {
  PREVIEW: { name: 'preview', label: 'Preview' },
  APPROVE: { name: 'approve', label: 'Approve' },
  PUBLISH: { name: 'publish', label: 'Publish' },
  PUBLISHED: { name: 'published', label: 'Published' },
};
export const AuditLogTypes = Object.freeze({
  PROMOTION_WEEK: 'PROMOTION_WEEK',
  PROMOTION: 'PROMOTION',
  TEMPLATE_BUILDER: 'TEMPLATE_BUILDER',
});
export const CalendarConfig = Object.freeze({
  ACCENT_COLOR: '#007397',
});
export const GridViewSource = Object.freeze({
  PREVIEW_ROWS: 'previewRows',
  ROWS: 'promotions',
});
export const GridViewCommitTypes = Object.freeze({
  TEMPLATE_BUILDER: 'TEMPLATE_BUILDER',
});
export const PromotionStatuses = Object.freeze({
  UNLOCKED: {
    display: 'Unlocked',
    value: 'UNLOCKED',
  },
  EDIT_LOCKED: {
    display: 'Edit Locked',
    value: 'EDIT_LOCKED',
  },
  CHANGE_REQUESTED: {
    display: 'Change Requested',
    value: 'CHANGE_REQUESTED',
  },
  REVIEW_LOCKED: {
    display: 'Review Locked',
    value: 'REVIEW_LOCKED',
  },
  REVIEW_CHANGE_REQUESTED: {
    display: 'Review Change Requested',
    value: 'REVIEW_CHANGE_REQUESTED',
  },
  PRINT_LOCKED: {
    display: 'Print Locked',
    value: 'PRINT_LOCKED',
  },
});
export const PromotionImageStatuses = Object.freeze({
  IMAGE_DOES_NOT_MATCH_ASIN: 'IMAGE_DOES_NOT_MATCH_ASIN',
  IMAGE_MATCHES_ASIN: 'IMAGE_MATCHES_ASIN',
  MISSING_IMAGE: 'MISSING_IMAGE',
  NO_IMAGES_FOUND_FOR_ASIN: 'NO_IMAGES_FOUND_FOR_ASIN',
  MANUALLY_CONFIRMED: 'MANUALLY_CONFIRMED',

});
export const DiscountTag = Object.freeze({
  EDLP: 'EDLP',
});

// TODO: Compile & reference all patterns from here
export const DateTimePatterns = Object.freeze({
  default: 'YYYY-MM-DD',
});

export const PromotionWeekDeadlines = Object.freeze({
  EDIT_DEADLINE: {
    amount: 8,
    unit: 'weeks',
  },
  REVIEW_DEADLINE: {
    amount: 5,
    unit: 'weeks',
  },
  PRINT_DEADLINE: {
    amount: 2,
    unit: 'weeks',
  },
});

export const PercentageColumns = Object.freeze([
  Columns.PRIME_DISCOUNT.name,
  Columns.PERCENT_FUNDED.name,
]);

export const MustFillColumns = Object.freeze([
  Columns.STORE_REGIONS.name,
  Columns.PRODUCT_CATEGORY.name,
  Columns.ASINS.name,
  Columns.DESCRIPTION.name,
  Columns.DISCOUNT_TAG.name,
  Columns.PROMOTION_TYPE.name,
  Columns.CIRCULAR_PRICE.name,
  Columns.UNIT_OF_MEASURE.name,
]);

export const ArrayColumns = Object.freeze([
  Columns.STORE_REGIONS.name,
  Columns.STORE_IDS.name,
  Columns.ASINS.name,
  Columns.PICTURED_ASINS.name,
]);

export const ObjectColumns = Object.freeze([]);

export const ArrayObjectColumns = Object.freeze([
  ...ArrayColumns,
  ...ObjectColumns,
]);

export const CellHighlightOverride = Object.freeze({
  REMOVED: 'REMOVED'
});