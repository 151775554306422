import { PromotionTypes } from '../../constants/promotions';
import { applyPercentageDiscount, isNum } from '../../helpers/common';
import getPromotionById from '../../reducers/gridViewPage/getPromotionById';
import { Columns } from '../../constants';
import { formatPriceForDisplay } from '../../helpers/promotions';
import { onCellInputUpdate } from '../../actionCreators';

export default (store, next, action) => {
  const { dispatch, getState } = store;
  const state = getState();
  const { GridViewPage } = state;
  const { activeCell } = GridViewPage;

  if (!activeCell) {
    return next(action);
  }

  const { columnName, rowId } = activeCell;
  if (![
    Columns.CIRCULAR_PRICE.name,
    Columns.IS_PRIME_BENEFIT.name,
    Columns.PRICE_CONDITIONS.name,
    Columns.PRIME_DISCOUNT.name,
    Columns.PRIME_MEMBER_LABEL.name,
    Columns.PROMOTION_TYPE.name,
    Columns.UNIT_OF_MEASURE.name,
  ].includes(columnName)) {
    return next(action);
  }

  const promotion = getPromotionById(GridViewPage, rowId);
  if (!promotion) {
    return next(action);
  }

  const promotionWithUpdatedActiveCellValue = {
    ...promotion,
    [columnName]: action.data
  };

  const { id, circularPrice, isPrimeBenefit, priceConditions, primeDiscount, primeMemberLabel, promotionType, unitOfMeasure } = promotionWithUpdatedActiveCellValue;

  if ([
    Columns.CIRCULAR_PRICE.name,
    Columns.IS_PRIME_BENEFIT.name,
    Columns.PRIME_DISCOUNT.name,
    Columns.PROMOTION_TYPE.name,
  ].includes(columnName)) {
    const shouldApplyPrimeDiscount = promotionType === PromotionTypes.FIXED_PRICE.value && isPrimeBenefit && isNum(primeDiscount) && isNum(circularPrice);
    if (shouldApplyPrimeDiscount) {
      const newPrimePrice = applyPercentageDiscount(
        circularPrice,
        primeDiscount,
      ).toFixed(2);
      dispatch(onCellInputUpdate(id, Columns.PRIME_MEMBER_LABEL.name, newPrimePrice));
    }
  }
  if (columnName === Columns.CIRCULAR_PRICE.name) {
    const {
      circularPriceDisplay,
    } = formatPriceForDisplay(circularPrice, isPrimeBenefit, priceConditions, primeMemberLabel, promotionType, unitOfMeasure);
    dispatch(onCellInputUpdate(id, Columns.CIRCULAR_PRICE_DISPLAY.name, circularPriceDisplay));
  }
  if (columnName === Columns.PRIME_MEMBER_LABEL.name) {
    const {
      primeMemberLabelDisplay
    } = formatPriceForDisplay(circularPrice, isPrimeBenefit, priceConditions, primeMemberLabel, promotionType, unitOfMeasure);
    dispatch(onCellInputUpdate(id, Columns.PRIME_MEMBER_LABEL_DISPLAY.name, primeMemberLabelDisplay));
  }
  if([
    Columns.IS_PRIME_BENEFIT.name,
    Columns.PRICE_CONDITIONS.name,
    Columns.UNIT_OF_MEASURE.name
  ].includes(columnName)) {
    const {
      circularPriceDisplay,
      primeMemberLabelDisplay
    } = formatPriceForDisplay(circularPrice, isPrimeBenefit, priceConditions, primeMemberLabel, promotionType, unitOfMeasure);
    dispatch(onCellInputUpdate(id, Columns.CIRCULAR_PRICE_DISPLAY.name, circularPriceDisplay));
    dispatch(onCellInputUpdate(id, Columns.PRIME_MEMBER_LABEL_DISPLAY.name, primeMemberLabelDisplay));
  }
  return next(action);
}